import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";

const backgroundImage = "/images/landing.jpeg";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#7fc7d9",
        backgroundPosition: "center",
      }}
    >
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Hayalinizdeki Bahçe ve Balkonlar İçin
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        Stil ve kalitenin buluştuğu özel minder yapımı ve mobilya tamiriyle açık
        alanlarınızı yeniden canlandırın.
      </Typography>

      {/*<Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="https://bmmhome.com"
        sx={{ minWidth: 200 }}
      >
        Çalışmalarımıza Göz Atın
      </Button>
    }
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Bizi deneyimleyin
      </Typography>*/}
    </ProductHeroLayout>
  );
}
