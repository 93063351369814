import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import { Link } from "@mui/material";

function ProductSmokingHero() {
  return (
    <Container
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 9,
      }}
    >
      <Button
        component="a"
        href="tel:+905353512362"
        sx={{
          border: "4px solid currentColor",
          borderRadius: 0,
          height: "auto",
          py: 2,
          px: 5,
        }}
      >
        <Typography variant="h4" component="span">
          Bizimle çalışmak ister misiniz? Aramak için tıklayın
        </Typography>
      </Button>
      <Typography variant="subtitle1" sx={{ my: 3 }}>
        <Link href="tel:+905353512362">+90 535 351 23 62</Link>
      </Typography>
      <Typography variant="subtitle1">
        Geçit Mahallesi, 538. Sokak No:1, Osmangazi/Bursa
      </Typography>
    </Container>
  );
}

export default ProductSmokingHero;
